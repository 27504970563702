







































































































































































































































































































































































































































































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { AllocationViewModel } from '../viewmodels/allocation-viewmodel'

@Observer
@Component({
  components: {},
})
export default class YourApplicationTable extends Vue {
  @Inject() vm!: AllocationViewModel
}
