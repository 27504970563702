






import { Component, Provide, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import YourApplicationTable from '../components/you-application-table.vue'
import { AllocationViewModel } from '../viewmodels/allocation-viewmodel'

@Observer
@Component({
  components: {
    YourApplicationTable,
  },
})
export default class Allocation extends Vue {
  @Provide() vm = new AllocationViewModel()

  beforeDestroy() {
    this.vm.destroy()
  }
}
