import { BlockChainHandler } from '@/blockchainHandler'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { FULL_100, Zero } from '@/constants'
import { bnHelper } from '@/helper/bignumber-helper'
import { promiseHelper } from '@/helper/promise-helper'
import { PoolModel } from '@/models/pool-model'
import { apiService } from '@/services/api-services'
import { PoolStore } from '@/stores/pool-store'
import { poolsStore } from '@/stores/pools-store'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import _, { map, sortBy, toNumber } from 'lodash'
import { action, computed, IReactionDisposer, observable, reaction, runInAction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { from, Subject, Subscription, timer } from 'rxjs'
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  mapTo,
  mergeMap,
  switchMap,
  takeUntil,
  takeWhile,
} from 'rxjs/operators'

export class PoolItemViewmodel {
  @observable pool: PoolModel | undefined = undefined
  @observable contract?: BlockChainHandler
  @observable tokenDecimals = 6
  @observable tradeDecimals = 6
  @observable startTime = 0
  @observable endTime = 0
  @observable tokenPrice = Zero
  @observable tokensFund = Zero
  @observable idoTokenID = Zero
  @observable tradeTokenID = Zero
  @observable vestingContract = Zero

  //vesting contract info
  @observable firstVestingTime: any = 0
  @observable linearVestingEnd: any = 0
  @observable maxClaimablePercentage: any = Zero
  @observable firstVestingPercentage = Zero
  @observable linearVestingStart = 0
  @observable linearVestingPeriod = 0

  //allocation info
  @observable acceptedTokenAmount = Zero
  @observable claimedTokenAmount = Zero
  @observable refundAmount = Zero
  @observable validAfterDate = 0
  @observable isRefund = true
  @observable maxClaimTokenAmount = Zero

  @observable currentTime = 0
  @observable status = ''
  @observable applyStatus = ''
  @observable poolStatus = false
  @observable isFinalized = false
  @observable claiming = false
  @observable refundLoading = false
  @observable loading = false
  @observable isEnableClaim = false
  @observable isDepositStarted = false
  @observable isLotteryPublic = false

  private _unsubcribe = new Subject()
  private _subscription: any = new Subscription()

  constructor(pool: PoolModel) {
    this.pool = pool
    this.updatePoolState()
    timer(1000, 1000)
      .pipe(
        takeUntil(this._unsubcribe),
        takeWhile(() => !this.isFinalized)
      )
      .subscribe(() => {
        this.updatePoolState()
      })
    if (!pool?.appID) return
    this.contract = new BlockChainHandler(pool)
  }

  @asyncAction *loadData() {
    try {
      if (this.contract) {
        for (let index = 0; index < 3; index++) {
          try {
            yield this.contract.init()
            break
          } catch (error) {
            console.log('error', error)
            if (index === 3) throw error
            else yield promiseHelper.delay(10000)
          }
        }
        this.syncContractState()
        this.currentTime = Math.floor(Date.now() / 1000)
        yield this.fetchContrainInfo()
      }
    } catch (error) {
      console.error('Allocation: ', this.pool?.name, this.pool?.tokenName, error)
    }
  }

  @action syncContractState() {
    if (this.contract) {
      const { tokenDecimals, tradeDecimals, startTime, endTime, tokenPrice } = this.contract.poolInfo
      this.tokenDecimals = tokenDecimals
      this.tradeDecimals = tradeDecimals
      this.startTime = startTime
      this.endTime = endTime
      this.tokenPrice = tokenPrice
    }
  }

  @asyncAction *fetchApplyStatus() {
    const res: any = yield apiService.applies.find({
      pool: this.pool?.id,
      investor: walletStore?.investor?.id,
      walletAddress: walletStore.account,
    }) || []
    this.applyStatus = res[0]?.status
  }

  @asyncAction *fetchContrainInfo() {
    if (!this.contract || !this.contract?.vestingID) return
    if (this.isLotteryPublic) {
      const res = yield this.contract?.fetchUserContrain(walletStore.account)
      const { claimedAmount, acceptedAmount, investedAmount, receivedRefundAmount, isRefund } = res
      this.acceptedTokenAmount = this.getTokenAmount(acceptedAmount)
      this.claimedTokenAmount = claimedAmount
      this.refundAmount = investedAmount.subUnsafe(acceptedAmount).subUnsafe(receivedRefundAmount)
      this.isRefund = isRefund
    }
    yield this.contract?.loadVestingInfo()
    const { firstVestingTime, firstVestingPercentage, linearVestingStart, linearVestingEnd, linearVestingPeriod } =
      this.contract.vestingInfo
    this.firstVestingTime = firstVestingTime
    this.firstVestingPercentage = firstVestingPercentage
    this.linearVestingStart = linearVestingStart
    this.linearVestingEnd = linearVestingEnd
    this.linearVestingPeriod = linearVestingPeriod
    yield this.calculateContrainInfo()
  }

  @asyncAction *calculateContrainInfo() {
    if (!this.linearVestingPeriod || !this.linearVestingEnd || !this.linearVestingStart) return
    const linearTimes = (this.linearVestingEnd - this.linearVestingStart) / this.linearVestingPeriod + 1
    const linearPeriodPercentage = FULL_100.subUnsafe(this.firstVestingPercentage).divUnsafe(
      FixedNumber.from(`${linearTimes}`)
    )
    if (this.currentTime < this.firstVestingTime) {
      this.maxClaimablePercentage = Zero
      this.validAfterDate = this.firstVestingTime
    } else if (this.currentTime < this.linearVestingStart) {
      this.maxClaimablePercentage = this.firstVestingPercentage
      this.validAfterDate = this.linearVestingStart
    } else if (this.currentTime < this.linearVestingEnd) {
      const readyLinearTimes = Math.floor((this.currentTime - this.linearVestingStart) / this.linearVestingPeriod) + 1
      this.validAfterDate = readyLinearTimes * this.linearVestingPeriod + this.linearVestingStart
      this.maxClaimablePercentage = this.firstVestingPercentage.addUnsafe(
        FixedNumber.from(`${readyLinearTimes}`).mulUnsafe(linearPeriodPercentage)
      )
    } else {
      if (this.currentTime >= this.linearVestingEnd) this.maxClaimablePercentage = FULL_100
      this.validAfterDate = this.linearVestingEnd
    }
    const maxClaimTokenAmount = this.maxClaimablePercentage.divUnsafe(FULL_100).mulUnsafe(this.acceptedTokenAmount)
    const claimableTokenAmount = maxClaimTokenAmount.subUnsafe(this.claimedTokenAmount)
    if (bnHelper.gt(claimableTokenAmount, FixedNumber.from('0.1'))) {
      this.maxClaimTokenAmount = claimableTokenAmount
      this.isEnableClaim = true
    } else {
      this.maxClaimTokenAmount = this.acceptedTokenAmount
        .mulUnsafe(
          this.currentTime < this.firstVestingTime
            ? this.firstVestingPercentage
            : this.currentTime < this.linearVestingEnd
            ? linearPeriodPercentage
            : Zero
        )
        .divUnsafe(FULL_100)
      this.isEnableClaim = false
    }
  }

  @action getTokenAmount(usdCost: FixedNumber) {
    if (!this.tokenPrice) return Zero
    return usdCost.divUnsafe(this.tokenPrice)
  }

  @asyncAction *updatePoolState() {
    this.poolStatus = yield this.getPoolStatus()
  }

  @asyncAction *getPoolStatus() {
    let status = ''
    const { whitelistEnd, depositStart, depositEnd, lotteryPublic } = this.pool?.data?.whitelistConfig || ({} as any)
    const currentTime = moment()
    const isWhitelistEnded = currentTime.isAfter(whitelistEnd)
    const isDepositStarted = currentTime.isAfter(depositStart)
    const isDepositEnded = currentTime.isAfter(depositEnd)
    const isLotteryPublic = currentTime.isAfter(lotteryPublic)
    if (!isWhitelistEnded) {
      status = 'Whitelist open'
    } else if (isWhitelistEnded && !isDepositStarted) {
      status = 'Whitelist end'
    } else if (!isDepositEnded) {
      status = 'Deposit open'
    } else if (!isLotteryPublic) {
      status = 'Picking Lottery'
    }
    this.isDepositStarted = isDepositStarted
    this.isLotteryPublic = isLotteryPublic
    return status
  }

  @asyncAction *loadPoolDetail() {
    this.loading = true
    try {
      yield this.loadData()
      yield this.fetchApplyStatus()

      if (this.isDepositStarted && (!this.hasDepositTicket || this.currentTime >= this.linearVestingEnd))
        this.isFinalized = true
      if (!this.isFinalized) {
        this._subscription.unsubscribe()
        this._subscription = timer(60000, 60000)
          .pipe(takeWhile(() => !this.isFinalized))
          .subscribe(async () => {
            await this.fetchApplyStatus()
            await this.calculateContrainInfo()
            runInAction(() => {
              this.currentTime = Math.floor(Date.now() / 1000)
              if (
                this.isDepositStarted &&
                (this.applyStatus !== 'whitelisted' || this.currentTime >= this.linearVestingEnd)
              )
                this.isFinalized = true
            })
          })
      }
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.loading = false
    }
  }

  @asyncAction *claim() {
    this.claiming = true
    try {
      yield this.contract?.claim(walletStore.account)
      snackController.success('Claim successfully')
      yield this.fetchContrainInfo()
    } catch (e: any) {
      snackController.error(e.message || e.msg)
    } finally {
      this.claiming = false
    }
  }

  @asyncAction *refund() {
    if (this.contract) {
      try {
        this.refundLoading = true
        yield this.contract.refund(walletStore.account)
        snackController.success(`Refund ${this.refundAmount.toString()} ${this.pool?.tradeToken} successfully`)
        yield this.fetchContrainInfo()
      } catch (e) {
        snackController.commonError(e)
      } finally {
        this.refundLoading = false
      }
    }
  }

  @computed get hasDepositTicket() {
    return this.applyStatus === 'whitelisted'
  }

  @computed get canClaim() {
    return this.isEnableClaim
  }

  @computed get isVisibleClaim() {
    return bnHelper.gt(this.acceptedTokenAmount, Zero)
  }

  @computed get canRefund() {
    return !this.isRefund && bnHelper.gt(this.refundAmount, Zero)
  }

  destroy() {
    this._unsubcribe.next()
    this._unsubcribe.complete()
    this._subscription.unsubscribe()
  }
}

export class AllocationViewModel {
  @observable mostRecenttFilter = false
  @observable totalRaisedFilter = false
  @observable textSearch = ''

  @observable numberItemDisplay = 5
  @observable currentPage = 1

  @observable allocationPools = []
  @observable fetchingData = false
  @observable validPoolIds: any[] = []
  @observable validPools: PoolItemViewmodel[] = []

  private _poolLoaderQueue = new Subject<PoolItemViewmodel>()
  private _unsubscribe = new Subject()
  private _disposers: IReactionDisposer[]

  constructor() {
    this._disposers = [
      reaction(
        () => walletStore.jwt,
        (jwt) => {
          this.allocationPools = []
          if (!!jwt) this.fetchData()
        },
        {
          fireImmediately: true,
        }
      ),
    ]
    this._poolLoaderQueue
      .pipe(
        takeUntil(this._unsubscribe),
        mergeMap((p) => from(p.loadPoolDetail()))
      )
      .subscribe()
  }

  destroy() {
    this._unsubscribe.next()
    this._unsubscribe.complete()
    this._disposers.forEach((d) => d())
    this.validPools.forEach((d) => d.destroy())
  }

  @asyncAction *fetchData() {
    this.fetchingData = true
    try {
      yield poolsStore.fetchPools()
      const applies = yield apiService.applies.find({
        walletAddress: walletStore.account,
        investor: walletStore.investor?.id,
        pool_null: false,
      })
      this.validPoolIds = map(applies, 'pool.id')
      const res: PoolModel[] = yield apiService.pools.find({ _sort: 'index:DESC' })
      const pools = res.filter((p) => this.validPoolIds.indexOf(p.id) !== -1)
      const validPools = sortBy(pools, (x: PoolModel) => -(x.index || 0))
      this.validPools = validPools.map((p) => new PoolItemViewmodel(p))
      this.validPools.forEach((p) => this._poolLoaderQueue.next(p))
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.fetchingData = false
    }
  }

  @action.bound onMostRecentFilterChange(val: boolean) {
    this.mostRecenttFilter = val
    if (this.mostRecenttFilter) this.totalRaisedFilter = false
    this.currentPage = 1
  }

  @action.bound onTotalRaisedFilterChange(val: boolean) {
    this.totalRaisedFilter = val
    if (this.totalRaisedFilter) this.mostRecenttFilter = false
    this.currentPage = 1
  }

  @action.bound onTextSearcheChange(textSearch: string) {
    this.textSearch = textSearch
  }

  @action.bound searchAllocationProject(page: number) {
    this.currentPage = page
  }

  @computed get sliceAllocations() {
    let allocationClones = this.validPools

    if (this.mostRecenttFilter) {
      allocationClones = sortBy(allocationClones, (allocation) =>
        moment(allocation.pool?.data?.whitelistConfig?.claimStart)
      )
    }

    if (this.totalRaisedFilter) {
      allocationClones = sortBy(allocationClones, (allocation) => toNumber(allocation.pool?.totalRaise))
    }

    if (this.textSearch) {
      allocationClones = allocationClones.filter((allocation) => {
        return allocation.pool?.name?.toLowerCase().includes(this.textSearch)
      })
    }

    return allocationClones
  }

  @computed get allocationItemsDisplayed() {
    return this.sliceAllocations.slice((this.currentPage - 1) * this.numberItemDisplay, this.currentPage * 5)
  }

  @computed get totalNumberPage() {
    return Math.ceil(this.sliceAllocations.length / this.numberItemDisplay)
  }
}
